<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <!-- <iframe :src="dashboard" width="100%" height="1000" frameborder="0" scrolling="auto"></iframe> -->
            <!-- 地图 -->
            <div class="canvas" style="opacity: .2"><iframe frameborder="0" src="/static/map.html" style="width: 100%; height: 100%"></iframe></div>
            <div class="loading" v-if="loding">
                <div class="loadbox"> <img src="../../assets/images/loading.gif"> 页面加载中... </div>
            </div>
            <!-- 头部时间 -->
            <div class="head">
                <h1>安正集团新零售数据中台</h1>
                <div class="weather"><span id="showTime"></span></div>
                <!-- <script>
            var t = null;
            t = setTimeout(time, 1000);

            function time() {
                clearTimeout(t);
                dt = new Date();
                var y = dt.getFullYear();
                var mt = dt.getMonth() + 1;
                var day = dt.getDate();
                var h = dt.getHours();
                var m = dt.getMinutes();
                var s = dt.getSeconds();
                document.getElementById("showTime").innerHTML = y + "年" + mt + "月" + day + "-" + h + "时" + m + "分" + s + "秒";
                t = setTimeout(time, 1000);
            }
            </script> -->
            </div>

            <!-- 数据统计内容 -->
            <div class="mainbox">
                <ul class="clearfix">
                    <!-- 左边统计数据 -->
                    <li>

                        <!-- 销售排行前10城市 -->
                        <div class="boxall" style="height: 2.6rem">
                            <div class="alltitle">{{items.month.name}}</div>
                            <div class="allnav" id="month"></div>

                            <!-- <div class="alltitle">销售排行前10城市</div> -->
                            <!-- <div class="allnav" id="CitySales"></div> -->
                            <div class="boxfoot"></div>
                        </div>
                        <div class="boxall" style="height: 2.6rem">
                            <div class="alltitle">{{items.city.name}}城市</div>
                            <div class="allnav" id="city"></div>

                            <!-- <div class="alltitle">销售排行前10城市</div> -->
                            <!-- <div class="allnav" id="CitySales"></div> -->
                            <div class="boxfoot"></div>
                        </div>
                        <!-- 品牌 -->
                        <!-- <div class="boxall" style="height: 2.6rem">
                            <div class="alltitle">品牌销售统计</div>
                            <div class="alltitle">品牌销售量</div>
                            <div class="allnav" id="BrandSalesRate"></div>
                            <div class="boxfoot"></div>
                        </div> -->
                        <!-- 店主推荐排行 -->
                        <div class="boxall" style="height: 2.6rem">
                            <div class="alltitle">客户来源统计</div>
                            <!-- <div class="alltitle">销售前10的店主</div> -->

                            <div class="allnav" id="CitySales2"></div>
                            <div class="boxfoot"></div>
                        </div>
                    </li>
                    <!-- 销售额统计 -->
                    <li>
                        <div class="bar">
                            <div class="barbox">
                                <ul class="clearfix">
                                    <li v-if="items.income.v" class="pulll_left counter" id="price">{{items.income.v}}</li>
                                    <li v-else class="pulll_left counter" id="price">-</li>
                                    <li class="pulll_left">wwww{{items.income.name}}</li>
                                </ul>
                            </div>
                            <div class="barbox">
                                <ul class="clearfix">
                                    <li v-if="items.user.v" class="pulll_left counter" id="price">{{items.user.v}}</li>
                                    <li v-else class="pulll_left counter" id="user">-</li>
                                    <li class="pulll_left">qqq{{items.user.name}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="map">
                            <div class="map1"><img src="../../assets/images/lbx.png"></div>
                            <div class="map2"><img src="../../assets/images/jt.png"></div>
                            <div class="map3"><img src="../../assets/images/map.png"></div>
                            <div class="map4" id="map_1"></div>
                        </div>
                    </li>
                    <!-- 右边 -->
                    <li>
                        <!-- 基础统计 -->
                        <div class="boxall" style="height: 2.6rem">
                            <div class="alltitle">aa{{items.data.name}}</div>
                            <div class="sycm">
                                <ul class="clearfix">
                                    <li>
                                        <h2 id="sale_price" v-if="items.data.order_num.v">bb{{items.data.order_num.v}}</h2>
                                        <h2 id="sale_price" v-else>-</h2>
                                        <span>cc{{items.data.order_num.name}}</span>
                                    </li>
                                    <li>
                                        <h2 id="order_num" v-if="items.data.order_right.v">dd{{items.data.order_right.v}}</h2>
                                        <h2 id="sale_price" v-else>-</h2>
                                        <span>ff{{items.data.order_right.name}}</span>
                                    </li>
                                    <li>
                                        <h2 id="sale_num" v-if="items.data.sales.v">gg{{items.data.sales.v}}</h2>
                                        <h2 id="sale_price" v-else>-</h2>
                                        <span>hh{{items.data.sales.name}}</span>
                                    </li>
                                </ul>
                                <div style="border-bottom: 1px solid rgba(255,255,255,.1)"></div>
                                <ul class="userlist">
                                    <!-- <li v-for="(item,index) in items.data.user" :key="index">
                                        <h2 v-if="item.v">ll{{item.v}}</h2>
                                        <h2 v-else>-</h2>
                                        <span>新增{{item.name}}</span>
                                    </li> -->
                                    <li>
                                        <h2>ll</h2>
                                        <span>新增</span>
                                    </li>
                                    <li>
                                        <h2>ll</h2>
                                        <span>新增</span>
                                    </li>
                                    <li>
                                        <h2>ll</h2>
                                        <span>新增</span>
                                    </li>
                                    <li>
                                        <h2>leave</h2>
                                        <span>新增</span>
                                    </li>
                                    <li>
                                        <h2>ll</h2>
                                        <span>新增</span>
                                    </li>
                                    <li>
                                        <h2>ll</h2>
                                        <span>新增</span>
                                    </li>
                                    <li>
                                        <h2>ll</h2>
                                        <span>新增</span>
                                    </li>
                                    <li>
                                        <h2>ll</h2>
                                        <span>新增</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="boxfoot"></div>
                        </div>

                        <!-- 消费记录 -->
                        <div class="boxall" style="height: 2.6rem">
                            <div class="alltitle">{{items.city.name}}称呼四</div>
                            <!-- <div class="alltitle">实时消费记录</div> -->
                            <div class="wrap">
                                <ul id="purchase">

                                </ul>
                            </div>
                            <div class="boxfoot"></div>
                        </div>
                        <!-- 品牌统计 -->
                        <div class="boxall" style="height: 2.6rem">
                            <div class="alltitle">订单来源统计</div>
                            <div id="BrandSales" style="height: 2.2rem; width: 100%;"></div>
                            <div class="boxfoot"></div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="back"></div>
        </div>
    </div>
</template>

<script>
let ws;
import { request } from '@/common/request';
import Breadcrumb from '@/components/Breadcrumb';
import { wb } from '../../../public/js/js'
export default {
    components: {
        Breadcrumb,
    },
    data() {
        return {
            menuList: ['概况', '数据大屏'],
            loding: false,
            items: {
                income: {},
                user: {},
                data: {
                    name: '',
                    order_num: {},
                    order_right: {},
                    sales: {},
                    user: [],
                    map: {
                        name: '中国',
                        v: [
                            { name: '上海', v: '408' },
                            { name: '江苏', v: '111' },
                            { name: '南京', v: '222' },
                            { name: '南阳', v: '333' },
                            { name: '洛阳', v: '4048' },
                            { name: '湖南', v: '555' },

                        ],
                    },
                },
                month: {
                    name: '',
                    num: [],
                    v: []
                },
                city: {
                    name: '',
                    num: [],
                    v: []
                }
            },
        }
    },
    created() {
        this.ws_connect()
        // $(".loading").fadeOut()
        $(document).ready(function() {
            var whei = $(window).width()
            $("html").css({ fontSize: whei / 20 })
            $(window).resize(function() {
                var whei = $(window).width()
                $("html").css({ fontSize: whei / 20 })
            });
            var html = $(".wrap ul").html()
            $(".wrap ul").append(html)
            var ls = $(".wrap li").length / 2 + 1
            let i = 0
            let ref = setInterval(function() {
                i++
                if (i == ls) {
                    i = 1
                    $(".wrap ul").css({ marginTop: 0 })
                    $(".wrap ul").animate({ marginTop: -'.52' * i + 'rem' }, 1000)
                }
                $(".wrap ul").animate({ marginTop: -'.52' * i + 'rem' }, 1000)
            }, 2400);
        });
        window['vueDefinedMyProp'] = (receiveParams) => {
            this.receiveParamsFromHtml(receiveParams)
        }
        // this.getconf()
    },
    methods: {
        receiveParamsFromHtml(res) {
            console.log('hhhhhhh', res)
        },
        //     getconf() {
        //         request.get('/common/conf').then(ret => {
        //             console.log(22222, ret.data)
        //             if (ret.code == 1) {
        //                 this.loading = false;
        //                 this.dashboard = ret.data.dashboard
        //             } else {
        //                 this.$message.error(ret.msg);
        //             }
        //         });
        //     },

        // }
        ws_connect() {
            this.loading = true
            let _this = this
            let ws;
            let headers = {
                token: localStorage.getItem("token"),
                platform: 'admin'
            }
            let token = localStorage.getItem("token")
            let platform = 'admin'
            // }
            // const url = `${location.protocol === 'https:' ? 'wss' : 'ws'}://${location.host}/socket`
            // ws = new WebSocket('ws://admin.lanmons.com' + '/xx/common/statistics')
            ws = new WebSocket("ws://c.anzhengshangcheng.cn/xx/common/statistics")

            // 这边开始连接webstock
            ws.onopen = function() {
                console.log('连接成功!')
            }
            ws.onerror = function(err) {
                console.log('连接失败!', err)
            }
            ws.onmessage = function(evt) {
                //开始处理数据
                let data = evt.data
                var items = JSON.parse(data);
                items = items.data
                _this.items = items
                this.loading = false
                console.log(2222222)
                //今日播报
                // if (items.today_price != "") {
                //     wb.today_price(items.today_price, _this)
                // }
                //实时购买人
                // if (items.purchase.length >= 1) {
                //     wb.purchase(items.purchase, _this)
                // }
                //首页主要显示
                // wb.page(items, _this)
                //品牌销售统计
                // if (items.plant_cap.length >= 1) {
                //     wb.BrandSales(items.plant_cap, _this)
                // }
                //城市排行
                wb.MonthSales(items.month.v, items.month.num, _this)
                wb.CitySales(items.city.v, items.city.num, _this)
                // wb.CitySales(['上海', '南京', 'aa', 'ss', 'dd', 'ff', 'gg', 'ee', 'rr', 'tt', 'yy', 'ii'], [1, 2, 3, 4, 5, 5, 6, 7, 7, 0, 9, 9], _this)


                //销售统计
                // wb.BrandSalesRate(items.brand, items.brand_num, _this);
                //店主带货
                // Shopkeeper(items.user_cap)
                // wb.CitySales2(items.user_cap, items.user_cap_num, _this)
                //地图
                // wb.map(items.map.v, _this)
                wb.map([
                    { name: '上海', v: '408' },
                    { name: '江苏', v: '111' },
                    { name: '南京', v: '222' },
                    { name: '南阳', v: '333' },
                    { name: '洛阳', v: '4048' },
                    { name: '湖南', v: '555' },

                ], _this)
            }
            ws.onclose = function(code, reason) {
                console.log("关闭连接")
            };

        },

    }
}
</script>

<style lang="scss" scoped>
// import 引入样式作用于全局
// @import '../../assets/css/comon0.css';
.container {
    background-color: #031225;
    padding: 20px 20px;
}
.userlist {
    display: flex;
}
// .sycm .userlist li {
//     flex: 1 !important;
// }
</style>
<style scoped src='../../assets/css/comon0.css'></style>