<template>
    <div>
        <!-- 开发中... -->
        <div class="top">
            <p class="table-title">
                数据统计
                <span>20分钟前更新</span>
            </p>
            <el-row class="box" :gutter="20">
                <el-col :span="4" v-for="(item, index) in statisticsList1" :key="index">
                    <div class="item" style="background: #f5f5f5">
                        <div class="item-top">
                            <p class="mun">{{ item.num }}</p>
                            <p class="title">{{ item.title }}</p>
                        </div>
                        <div class="btn" @click="goDetail(item.path)">查看</div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="top">
            <p class="table-title">待办事项</p>
            <el-row class="box" :gutter="20">
                <el-col :span="6" v-for="(item, index) in mattersList" :key="index">
                    <div class="item" style="background: #f5f5f5">
                        <div class="item-top">
                            <p class="mun">{{ item.num }}</p>
                            <p class="title">{{ item.title }}</p>
                        </div>
                        <div class="btn" @click="goDetail(item.path)">查看</div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="top" v-if="false">
            <p class="table-title">常用功能</p>
            <el-row class="box" :gutter="20">
                <el-col :span="6" v-for="item in common_menu" :key="item.id">
                    <div class="item" style="background: #f5f5f5">
                        <p class="common">{{ item.name }}</p>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { request } from '@/common/request';
export default {
    data() {
        return {
            common_menu: [],
            statisticsList1: [],
            statisticsList2: [],
            mattersList: []
        };
    },

    created() {
        this.loadData();

        console.log(this.$router);
    },
    methods: {
        goDetail(path) {
            this.$router.push({ path: path });
        },
        //获取常用功能
        loadData() {
            this.loading = true;
            let _this = this;
            request.get('/statistics', {}).then((ret) => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.common_menu = ret.data.common_menu;
                    this.mattersList = ret.data.stay;
                    this.statisticsList1 = ret.data.complete;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    background-color: #797979;
}

.top {
    margin-bottom: 20px;
    background-color: #ffffff;
    padding: 30px;
}

.table-title {
    height: 80px;
    font-size: 18px;
    color: #333333;
    font-weight: 900;
    line-height: 80px;
    border-bottom: 1px solid #eeeeee;
}

.table-title span {
    font-size: 14px;
    color: #666666;
    font-weight: 0;
    margin-left: 30px;
}

.box {
    padding: 30px 0 0 0;
    box-sizing: border-box;
}
.item {
    padding: 30px;
}

.item-top {
    border-bottom: 1px solid #eeeeee;
}
.mun {
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 20px;
    color: #333333;
}

.title {
    font-size: 16px;
    font-weight: 0;
    margin-bottom: 20px;
    font-size: 16px;
    color: #333333;
}
.btn {
    cursor: pointer;
    width: 56px;
    height: 30px;
    font-size: 14px;
    color: #333333;
    text-align: center;
    line-height: 30px;
    background-color: rgba(3, 18, 37, 0.1);
    margin-top: 20px;
}
.btn:hover {
    color: #ffffff;
    background-color: #409eff;
}
.common {
    font-size: 16px;
    color: #333333;
    text-align: center;
    cursor: pointer;
}
</style>
