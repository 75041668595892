<template>
    <div class="container">
        <el-row>
            <el-col :span="12">
                <el-tabs v-model="activeName" @tab-click="statisticTabClick">
                    <el-tab-pane label="今日数据" name="today"></el-tab-pane>
                    <el-tab-pane label="昨日数据" name="yesterday"></el-tab-pane>
                    <el-tab-pane label="本月数据" name="month"></el-tab-pane>
                    <el-tab-pane label="全部数据" name="fourth"></el-tab-pane>
                </el-tabs>
                <el-row v-if="commission">
                    <el-col :span="6">
                        <el-statistic title="分销佣金">
                            <template slot="formatter"> {{commission.commission_amount}} </template>
                        </el-statistic>
                    </el-col>
                    <el-col :span="6">
                        <el-statistic title="分销订单">
                            <template slot="formatter"> {{commission.order_num}} </template>
                        </el-statistic>
                    </el-col>
                    <el-col :span="6">
                        <el-statistic title="新增用户">
                            <template slot="formatter"> {{commission.spread_user_num}} </template>
                        </el-statistic>
                    </el-col>
                    <el-col :span="6">
                        <el-statistic title="营业额">
                            <template slot="formatter"> {{commission.order_amount}} </template>
                        </el-statistic>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="2">
                <!--  empty-->
                <p>&nbsp;</p>
            </el-col>
            <el-col :span="3">
                <div class="" style="width: 100%; text-align: center">
                    <div v-if="distributorSelf&&distributorSelf.mp_index_qrcode">
                        <el-avatar :src="distributorSelf&&distributorSelf.mp_index_qrcode" shape="square" :size="128"></el-avatar>
                        <p>分站预览</p>
                        <a :href="distributorSelf.mp_index_qrcode">下载</a>
                    </div>
                    <div v-else style="display:flex;flex-direction: column; align-items: center;">
                        <el-avatar shape="square" :src="invalidQrcode" :size="128"></el-avatar>
                        <el-button type='text' @click="reloadMpIndexQrcode(pageIndex,1)" style="margin-top:10px;">点击生成</el-button>
                    </div>

                </div>
            </el-col>

            <el-col  :span="3">
                <div class="" style="width: 100%; text-align: center">
                    <div v-if="distributorSelf&&distributorSelf.mp_member_card_qrcode">
                        <el-avatar :src="distributorSelf&&distributorSelf.mp_member_card_qrcode" shape="square" :size="128"></el-avatar>
                        <p>会员卡券</p>
                        <a :href="distributorSelf.mp_member_card_qrcode" v-if="distributorSelf&&distributorSelf.mp_member_card_qrcode">下载</a>
                    </div>
                    <div v-else style="display:flex;flex-direction: column; align-items: center;">
                        <el-avatar shape="square" :src="invalidQrcode" :size="128"></el-avatar>
                        <p> <el-button type='text' style="margin-top:10px;" @click="reloadMpIndexQrcode(pagesMunber,2)">重新生成</el-button></p>
                    </div>
                </div>
                <div></div>
            </el-col>
            <!-- <el-col :span="3">
                <div class="" style="width: 100%; text-align: center">
                    <canvas id="posterPreviewCodeCanvas"></canvas>
                    <p>海报预览</p>
                </div>
            </el-col> -->
        </el-row>

        <div class="top-statistics-date-picker" v-if="activeName=='fourth'">
            <el-date-picker v-model="statisticsTimeRange" type="datetimerange" @change='selDateTime' :picker-options="pickerOptions" value-format="timestamp" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small" align="right">
            </el-date-picker>
        </div>
        <el-row>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="3">会员卡分佣：80%</el-col>
            <el-col :span="3">订单分佣：80%</el-col>
            <el-col :span="2">账户余额：0.00</el-col>
            <el-col :span="3">
                <el-button type="primary" @click="withdrawDialogVisible = true">申请提现</el-button>
            </el-col>
        </el-row>
        <div class="clipboard-row">
            <div class="clipboard-block">
                <el-row class="clipboard-title">公众号推广小程序</el-row>
                <el-row class="link-row">
                    <span class="link-title">进入公众号→小程序管理→关联小程序 </span>
                    <span class="link-content">(小程序APPID:{{ mpConfig.appid }})</span>
                    <el-button type="primary" size="small" @click="copyShareLink" v-clipboard:copy="mpConfig.appid" v-clipboard:success="copySuccess" v-clipboard:error="copyError">复制</el-button>
                </el-row>
                <el-row class="link-row">
                    <span class="link-title">首页地址:</span>
                    <span class="link-content">pages/index/index?agent_id={{ loginUser.uid }}</span>
                    <el-button type="primary" size="small" @click="copyShareLink" v-clipboard:copy="`pages/index/index?agent_id=` + loginUser.uid" v-clipboard:success="copySuccess" v-clipboard:error="copyError">复制</el-button>
                </el-row>
                <el-row class="link-row" v-for="(item, index) in specList" :key="index">
                    <el-avatar shape="square" :size="32" :src="item.logo" style="position: relative; top: 10px"></el-avatar>
                    <span class="link-title"> {{ item.name }} 地址: </span>
                    <span class="link-content">pages/index?task_id={{ item.id }}&agent_id={{ loginUser.uid }} </span>
                    <el-button type="primary" size="small" @click="copyShareLink" v-clipboard:copy="`pages/index?task_id=` + item.id + '&agent_id=' + loginUser.uid" v-clipboard:success="copySuccess" v-clipboard:error="copyError">复制
                    </el-button>
                </el-row>

            </div>
            <div class="clipboard-block">
                <el-row class="clipboard-title">小程序二维码分享</el-row>
                <el-row class="link-row">
                    <span class="link-title">进入公众号→小程序管理→关联小程序 </span>
                    <span class="link-content">(小程序APPID:{{ mpConfig.appid }})</span>
                    <el-button type="primary" size="small" v-clipboard:copy="mpConfig.appid" v-clipboard:success="copySuccess" v-clipboard:error="copyError">复制</el-button>
                </el-row>

                <el-row class="link-row" v-for="(item, index) in specList" :key="index">
                    <span class="link-title">
                        {{ item.name }}
                        <!-- <el-avatar :src="item.qrcodeUrl" :size="32" style="position: relative; top: 20px"></el-avatar> -->
                        地址:
                    </span>
                    <el-button type="primary" size="small" @click="downloadSpecialMpCode(item.id)">下载</el-button>
                </el-row>
            </div>
        </div>
        <el-row style="position: relative; right: 100px; bottom: 40px; width: 100%; text-align: right">
            <el-link type="primary" :underline="false" @click="gocliim">小程序码生成工具（点击跳转草料二维码）</el-link>
        </el-row>
        <el-dialog title="关联微信提现" width="30%" center :visible.sync="withdrawDialogVisible">
            <div style="text-align: center; margin-bottom: 20px">
                <el-avatar shape="square" :size="160" src=""></el-avatar>
            </div>
            <p style="text-align: center">扫码关注公众号</p>
        </el-dialog>
    </div>
</template>

<script>
import QRCode from 'qrcode';
import {request} from '@/common/request';
import {CommonStateOk} from '@/common/const';
import download from '@/page/lineage/Download.vue';

export default {
    computed: {
        download() {
            return download;
        }
    },

    data() {
        return {
            statisticsTimeRange: [],
            activeName: 'today',
            specList: [], //专场列表
            loginUser: {},
            pickerOptions: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            },
            mpConfig: {},
            posterPreviewCode: 'https://liczdwedf.com/6666',
            distributorSelf: {},
            withdrawDialogVisible: false,
            invalidQrcode: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4jV4k012883635890801f17cfb180c1e38bc5a.png",
            commission: null,
            queryComm: {
                start_time: null,
                end_time: null,
            },
            pageIndex: {
                jumpPage: 'pages/index/index',
                params: 'agent_id={uid}',
                propertyKey: 'mp_index_qrcode',
            },
            pagesMunber: {
                jumpPage: 'pagesExt/membershipCard/list',
                params: 'agent_id={uid}',
                propertyKey: 'mp_member_card_qrcode',
            },
        };
    },
    created() {
        this.loadData();
        this.getSpecialList();
        this.getLocalUserInfo();
        this.getDistributorSelf();
        this.loadMpConfig();
        console.log(this.$router);
        // this.getCommission();
        this.statisticTabClick();

        console.log(JSON.parse(localStorage.getItem('user')))

        this.pageIndex.params = `agent_id=${JSON.parse(localStorage.getItem('user')).uid}`;
        this.pagesMunber.params = `agent_id=${JSON.parse(localStorage.getItem('user')).uid}`;
    },
    mounted() {
        // this.setPreviewQrcode('posterPreviewCodeCanvas', this.posterPreviewCode);
    },
    methods: {

        // 分佣数据
        getCommission() {
            request.get('/distributor/dashboard', this.queryComm).then(res => {
                if (res.code == 1) {
                    this.commission = res.data;
                }
            })
        },

        // 选择的时间
        selDateTime(val) {
            if (val) {
                this.queryComm.start_time = val[0];
                this.queryComm.end_time = val[1];
            } else {
                this.queryComm.start_time = null;
                this.queryComm.end_time = null;
            }
            this.getCommission();
        },

        copySuccess(e) {
            this.$notify.success({
                title: '复制成功',
                message: '文本：' + e.text,
                showClose: false
            });
        },
        copyError(e) {
            this.$notify.error({
                title: '复制失败',
                message: '复制失败！',
                showClose: false
            });
        },
        statisticTabClick() {

            const date = new Date();
            const currentYear = date.getFullYear();
            const currentMonth = date.getMonth() + 1;
            const today = date.getDate();

            // var timestamp = new Date().getTime(); // 1604297892942

            switch (this.activeName) {
                case 'today'://今天
                    let todayStart = new Date(`${currentYear}-${currentMonth}-${today} 00:00:00`).getTime();
                    let todayEnd = new Date(`${currentYear}-${currentMonth}-${today} 23:59:59`).getTime();
                    this.queryComm.start_time = todayStart;
                    this.queryComm.end_time = todayEnd;

                    break;
                case 'yesterday'://昨天
                    let yesterdayStart = new Date(`${currentYear}-${currentMonth}-${today} 00:00:00`).getTime() - 24 * 60 * 60 * 1000;
                    let yesterdayEnd = new Date(`${currentYear}-${currentMonth}-${today} 23:59:59`).getTime() - 24 * 60 * 60 * 1000;
                    this.queryComm.start_time = yesterdayStart;
                    this.queryComm.end_time = yesterdayEnd;

                    break;
                case 'month'://本月
                    let monthStart = new Date(`${currentYear}-${currentMonth}-01 00:00:00`).getTime();
                    let monthEnd = new Date().getTime();
                    this.queryComm.start_time = monthStart;
                    this.queryComm.end_time = monthEnd;
                    break;

                case 'fourth'://自定义筛选
                    this.queryComm.start_time = this.statisticsTimeRange[0];
                    this.queryComm.end_time = this.statisticsTimeRange[1];
                    break;
            }
            this.getCommission();
        },
        getSpecialList() {
            request.get('/goods/special/list', { state: CommonStateOk }).then((ret) => {
                if (ret.code === 1) {
                    this.specList = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        getLocalUserInfo() {
            this.loginUser = JSON.parse(localStorage.getItem('user'));
        },
        getDistributorSelf() {
            request.get('/distributor/self', {}).then((ret) => {
                if (ret.code === 1) {
                    this.distributorSelf = ret.data.detail;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        setPreviewQrcode(documentId, content) {
            console.log(documentId);
            console.log(content);
            let canvas = document.getElementById(documentId);
            console.log(canvas);
            QRCode.toCanvas(canvas, content, function(error) {
                if (error) {
                    console.error(error);
                }
                console.log('success!');
            });
        },
        goDetail(path) {
            this.$router.push({ path: path });
        },
        gocliim() {
            window.open('https://cli.im/');
        },
        //获取常用功能
        loadData() {
            this.loading = true;
            let _this = this;
            request.get('/statistics', {}).then((ret) => {
                if (ret.code === 1) {
                    this.loading = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //获取常用功能
        loadMpConfig() {
            this.loading = true;
            let _this = this;
            request.get('/system/weixin/mp', {}).then((ret) => {
                if (ret.code === 1) {
                    this.mpConfig = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        copyShareLink(url) { },

        downloadSpecialMpCode(id, index) {
            let _this = this;
            request.get('/goods/special/downloadMpCode', { id: id }).then((ret) => {
                if (ret.code === 1) {
                    console.log(ret.data);
                    //_this.specList[index].qrcodeUrl =ret.data.qrcode
                    window.open(ret.data.qrcode);
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        reloadMpIndexQrcode(item, type) {
            let _this = this;
            request.post('/distributor/reloadMpIndexQrcode', item).then((ret) => {
                if (ret.code === 1) {
                    _this.distributorSelf = ret.data.detail
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    height: 100%;
    position: relative;

    .top-statistics-date-picker {
        position: absolute;
        left: 429px;
        top: 27px;
    }

    .clipboard-row {
        display: flex;
        justify-content: space-around;

        .clipboard-block {
            width: 48%;
            background-color: #f1f1f1;
            height: 500px;
            padding: 40px 10px 10px 40px;
            box-sizing: border-box;
            margin-top: 40px;
            border-radius: 10px;
            .clipboard-title {
                font-size: 18px;
                font-weight: bold;
            }
            .link-row {
                line-height: 36px;
                height: 36px;
                .link-content {
                    margin-right: 40px;
                }
            }
        }
    }
}

.top {
    margin-bottom: 20px;
    background-color: #ffffff;
    padding: 30px;
}

.table-title {
    height: 80px;
    font-size: 18px;
    color: #333333;
    font-weight: 900;
    line-height: 80px;
    border-bottom: 1px solid #eeeeee;
}

.table-title span {
    font-size: 14px;
    color: #666666;
    font-weight: 0;
    margin-left: 30px;
}

.box {
    padding: 30px 0 0 0;
    box-sizing: border-box;
}

.item {
    padding: 30px;
}

.item-top {
    border-bottom: 1px solid #eeeeee;
}

.mun {
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 20px;
    color: #333333;
}

.title {
    font-size: 16px;
    font-weight: 0;
    margin-bottom: 20px;
    font-size: 16px;
    color: #333333;
}

.btn {
    cursor: pointer;
    width: 56px;
    height: 30px;
    font-size: 14px;
    color: #333333;
    text-align: center;
    line-height: 30px;
    background-color: rgba(3, 18, 37, 0.1);
    margin-top: 20px;
}

.btn:hover {
    color: #ffffff;
    background-color: #409eff;
}

.common {
    font-size: 16px;
    color: #333333;
    text-align: center;
    cursor: pointer;
}
.img_boxs {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
        width: 128px;
        height: 128px;
    }
}
</style>
