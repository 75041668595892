<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="模块名称:">
                    <el-select v-model="queryParams.ty" placeholder="全部" clearable @change="changeQuery">
                        <el-option label="用户信息" :value="0"></el-option>
                        <el-option label="订单" :value="1"></el-option>
                        <el-option label="售后" :value="2"></el-option>
                        <el-option label="专场" :value="3"></el-option>
                        <el-option label="套装购" :value="4"></el-option>
                        <el-option label="拼团" :value="5"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="全部" clearable @change="changeQuery">
                        <el-option label="导入中" :value="1"></el-option>
                        <el-option label="已导入" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" :cell-style="cellstyle" stripe border :data="pageInfo.list" class="el-table" style="margin-top: 30px;">
                <el-table-column label="操作id" prop="uid" align="center" width="100"></el-table-column>
                <el-table-column label="模块名字" prop="ty" align="center" width="200">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.ty === 0" type="info">用户信息</el-tag>
                        <el-tag v-if="scope.row.ty === 1" type="info">订单</el-tag>
                        <el-tag v-if="scope.row.ty === 2" type="info">售后</el-tag>
                        <el-tag v-if="scope.row.ty === 3" type="info">专场</el-tag>
                        <el-tag v-if="scope.row.ty === 4" type="info">套装购</el-tag>
                        <el-tag v-if="scope.row.ty === 5" type="info">拼团</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="链接" prop="url" align="center"></el-table-column>
                <el-table-column label="导出时间" prop="created_at" align="center"></el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="80">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">导入中</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">已导入</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.state === 2" type="text" class="green" @click="download(scope.row.url, 2)">下载</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            menuList: ['系统', '下载'],
            loading: false,
            queryParams: {
                name: '',
                page: 1,
                pagesize: 10,
                platform: 'admin'
            },
            pageInfo: {},
            addVisible: false,
            title: '添加热词',
            form: {
                name: '',
                state: null,
                time: null
            },

        };
    },
    created() {
        this.loadData();
    },
    methods: {
        cellstyle() {
            return 'text-align: center';
        },
        //获取后台日志列表
        loadData() {
            this.loading = true;
            let _this = this
            request.get('/export/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        download(url) {
            window.open(url)
        }
    }
};
</script>

<style scoped>
</style>